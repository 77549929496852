import React from "react"
import './hero.css';
import Button from '../components/button';

const Hero = () =>
{
    return (<section className="default-wrapper">
        <div className="hero-container">
            <div className="hero-content-left">
                <div>
                    <h1 className="hero-h1">Speed up customer acquisition for your business.</h1>
                    <p className="hero-p">Transform your ideas into reality with our custom web and mobile app solutions. Dream it, and let us create it!</p>
                </div>
                <div className="flex flex-row pt-1 pb-1">
                    <Button title={'Get started'} className={'primary-button mr-1'} />
                    <Button title={'View services'} className={'secondary-button'} />
                </div>
            </div>
            <div className="hero-content-right">
                <img className="hero-image" src="https://images.unsplash.com/photo-1648824573599-39cdd358edfb?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDU0fHxjdXN0b21lciUyMGFjcXVpc2l0aW9ufGVufDB8fHx8MTcwNzgyODg2OHww&amp;ixlib=rb-4.0.3&amp;w=800" alt="image" />
            </div>
        </div>
    </section>)
}


export default Hero;
import React from "react";
import './social.css';

const Social = () =>
{
    return (<div>
        <div className="text-white inter font-16">Follow us:</div>
        <div className="flex flex-row justify-flex-start social-icons">
            <a
                href="https://example.com"
                target="_blank"
                rel="noreferrer noopener"
            >
                <img
                    alt="image"
                    src="/Icons/linkedin-200h.png"
                    className=""
                />
            </a>
            <a
                href="https://example.com"
                target="_blank"
                rel="noreferrer noopener"
            >
                <img
                    alt="image"
                    src="/Icons/instagram-200h.png"
                />
            </a>
            <a
                href="https://example.com"
                target="_blank"
                rel="noreferrer noopener"
            >
                <img
                    alt="image"
                    src="/Icons/twitter-200h.png"
                />
            </a>
        </div>
    </div>)
}

export default Social
import React from 'react'
import ReactDOM from 'react-dom'
import
  {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
  } from 'react-router-dom'

import './style.css'
import NotFound from './views/not-found'
import HomePage from './pages/HomePage'

const App = () =>
{
  return (
    <Router>
      <Switch>
        <Route component={HomePage} exact path="/" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))

import React from "react";
import Mark from "../components/mark";

const WebDevelopmentLeft = () =>
{
    return (<div>
        <header class="lr-content-heading">
            <h2 class="section-heading lr-content-heading-font-size">Custom website development</h2>
            <p class="section-description lr-content-paragraph">
                <span>Empower your online presence with our Custom Website Development services. Our team of skilled developers leverages the latest web technologies to create bespoke websites that are not only visually appealing but also highly functional and user-friendly. We focus on understanding your business objectives to deliver a tailored web solution that enhances your brand's digital footprint, engages your audience, and drives conversions. Whether it's an ecommerce platform, a portfolio site, or a complex web application, our custom-built websites are optimized for performance, SEO, and cross-platform compatibility. Elevate your online presence and achieve your digital goals with our personalized web development solutions.</span>
            </p>
        </header>
        <div class="mark-checkmarks">
            <Mark label="Websites that align with your brand identity." />
            <Mark label="Optimized for Performance and SEO." />
            <Mark label="Seamless user experience across all devices and browsers." />
        </div>
    </div>)
}

export default WebDevelopmentLeft
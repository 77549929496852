import React from 'react'
import { Link } from 'react-router-dom'
import './navbar.css'
import Button from './button'

const Navbar = (props) =>
{
  return (
    <nav className={`navbar-navbar default-wrapper`}>
      <Link to="/" className="navbar-navlink">
        <img
          alt="codeconsulter-logo"
          src="/Branding/code-negative-200h.gif"
          className="navbar-image"
        />
      </Link>
      <div className="navbar-nav-content">
        <div className="navbar-nav-links">
          <a href="/#services-area" className="navbar-link nav-link">
            Services
          </a>
          <a href="/#why-us" className="navbar-link1 nav-link">
            Why us
          </a>
          <span className="nav-link navbar-link2">Projects</span>
          <Link to="/contact" className="navbar-link3 nav-link">
            Contact
          </Link>
        </div>
        <Button title={'Get started'} className={'secondary-button'} />

        <div id="open-mobile-menu" className="navbar-hamburger get-started">

        </div>
      </div>

    </nav>
  )
}


export default Navbar

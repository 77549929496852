import React from 'react'

import PropTypes from 'prop-types'

import './input.css'

const Input = (props) => {
  return (
    <div className={`input-container ${props.rootClassName} `}>
      <label className="input-text">{props.text}</label>
      <input
        type="text"
        placeholder={props.textinputPlaceholder}
        className="input-textinput input"
      />
    </div>
  )
}

Input.defaultProps = {
  textinputPlaceholder: 'Email',
  text: 'Email',
  rootClassName: '',
}

Input.propTypes = {
  textinputPlaceholder: PropTypes.string,
  text: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Input

import React from "react";
import Mark from "../components/mark";

const WhatWeDoLeft = () =>
{
    return (<div>
        <header class="lr-content-heading">
            <h2 class="section-heading lr-content-heading-font-size">Strategic Insights, Superior Solutions</h2>
            <p class="section-description lr-content-paragraph">
                <span>Elevate your tech strategy with our expert software consultancy. We offer tailored advice, innovative solutions, and strategic planning to navigate complex challenges and drive your business forward. Partner with us for a competitive edge.</span>
            </p>
        </header>

        <div class="mark-checkmarks">
            <Mark label="Website Development" />
            <Mark label="Mobile App Development" />
            <Mark label="Tailored Advice" />
            <Mark label="Innovative Solutions" />
            <Mark label="Strategic Planning" />
        </div>
    </div>)
}

export default WhatWeDoLeft
import React from "react";
import Mark from "../components/mark";

const MobileMasteryRight = () =>
{
    return (<div>
        <header class="lr-content-heading">
            <h2 class="section-heading lr-content-heading-font-size">Tailormade mobile development</h2>
            <p class="section-description lr-content-paragraph">
                <span>Unlock the full potential of your business with our Tailormade Mobile App Development services. We specialize in creating custom mobile solutions designed to meet your specific business needs and goals. Our team of experienced developers employs the latest technologies and innovative approaches to deliver intuitive, scalable, and secure mobile applications that enhance user engagement and drive growth. Whether you're looking to improve customer experience, streamline operations, or enter new markets, our bespoke apps are crafted to provide you with a competitive edge. Partner with us to transform your ideas into reality and propel your business forward in the digital age.</span>
            </p>
        </header>

        <div className="mark-checkmarks">
            <Mark label="Designed to meet specific business needs and goals." />
            <Mark label="Utilization of the latest technologies and innovative approaches." />
            <Mark label="Improve customer experience and drive business growth." />
        </div>
    </div>)
}

export default MobileMasteryRight
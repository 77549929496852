import React from "react";
import './button.css';

const Button = ({
    title,
    className,
    onClick
}) =>
{

    return (<button className={`button ${className}`} onClick={onClick}>
        <span>{title}</span>
    </button>)
}

export default Button;
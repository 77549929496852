import React from "react";

const MobileMasteryMiddle = () =>
{

    return (<div>
        <h3 className="section-heading text-center pt-2 pb-2">Why do you need a mobile app?</h3>
        <p className="text-center section-description pt-1 pb-1">Unlock your business's potential with our mobile app solutions! Enhance engagement, boost accessibility, and provide personalized experiences to skyrocket brand visibility and loyalty. Transform your customer journey today!</p>
    </div>)
}

export default MobileMasteryMiddle;
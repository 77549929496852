import React from 'react';
import Navbar from '../components/navbar';
import Hero from '../components/hero';
import Footer from '../components/footer';
import LeftRightContent from '../components/left-right-content';
import WhatWeDoLeft from '../partials/what-we-do-left';
import WhatWeDoRight from '../partials/what-we-do-right';
import MobileMasteryLeft from '../partials/mobile-mastery-left';
import MobileMasteryRight from '../partials/mobile-mastery-right';
import WebDevelopmentLeft from '../partials/web-development-left';
import WebDevelopmentRight from '../partials/web-development-right';
import MobileMasteryMiddle from '../partials/mobile-mastery-middle';

const HomePage = () =>
{
    return (<div className="main-container">
        <Navbar />
        <Hero />
        <LeftRightContent title={'What we do'} left={<WhatWeDoLeft />} right={<WhatWeDoRight />} />
        <LeftRightContent title={'Mobile Mastery'} left={<MobileMasteryLeft />} middle={<MobileMasteryMiddle />} right={<MobileMasteryRight />} />
        <LeftRightContent title={'Web Development Unleashed'} left={<WebDevelopmentLeft />} right={<WebDevelopmentRight />} />
        <Footer />
    </div>)
}

export default HomePage;
import React from "react";
import "./left-right-content.css";

const LeftRightContent = ({ title, left, middle, right }) =>
{
    return (<div className="lr-container">
        <div className="default-wrapper">
            <div className="lr-heading">
                <h2 className="font-white font-40 poppins weight-600">{title}</h2>
            </div>

            <div className="">{middle}</div>
            <div className="flex flex-row lr-content">
                <div className="flex flex-column">{left}</div>
                <div className="flex flex-column">{right}</div>
            </div>
        </div>
    </div>);
}

LeftRightContent.defaultProps = {
    middle: '',
}

export default LeftRightContent;
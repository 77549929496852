import React from "react";
import Input from './input';
import Button from './button';
import "./subscribe.css";

const SubscribeComponent = () =>
{
    return (<div className="subscribe">
        <div className="subscribe-heading">Subscribe to our newsletter</div>
        <div className="subscribe-form">
            <Input textinputPlaceholder={'Enter your email'} text={''} />
            <Button title={'Subscribe now'} className={'primary-button'} />
        </div>
        <h6 className="subscribe-small">
            By subscribing to our newsletter you agree with our Terms and
            Conditions.
        </h6>
    </div>
    )
}

export default SubscribeComponent;
import React from "react";

const ListDiv = ({
    title,
    listItems
}) =>
{
    return (<div className="footer-list">
        <div>
            <span className="footer-header">{title}</span>
        </div>
        <div className="footer-link-list">
            {listItems?.map((item, index) =>
            {
                return <a className="block footer-link" key={index} href={item.url}>{item.title}</a>
            })}
        </div>
    </div>)
}

export default ListDiv
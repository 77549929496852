import React from 'react';
import './footer.css';
import Social from './social';
import ListDiv from './list-div';
import SubscribeComponent from './subscribe';

const solutions = [{ title: 'Web Development', url: 'https://example.com' }, { title: 'Mobile Development', url: 'https://example.com' }, { title: 'UI/UX Design', url: 'https://example.com' }];
const company = [{ title: 'About', url: 'https://example.com' }, { title: 'Careers', url: 'https://example.com' }, { title: 'Blog', url: 'https://example.com' }];

const Footer = () =>
{
    return (<footer className="footer">
        <div className="default-wrapper">
            <main className="footer-content flex flex-row">
                <div className="flex-column">
                    <div className="flex flex-row">
                        <header className="footer-logo flex flex-column">
                            <img
                                alt="image"
                                src="/Branding/code-negative-200h.gif"
                                className=""
                            />
                            <span className="text-white inter pt-1 pb-1 font-14 mr-1">
                                Transform your ideas into reality with our custom web and mobile app solutions. Dream it, and let us create it!
                            </span>

                            <Social />
                        </header>
                        <header className="flex flex-column">
                            <div className="flex flex-row">
                                <ListDiv title={'Solutions'} listItems={solutions} />
                                <ListDiv title={'Company'} listItems={company} />
                            </div>
                        </header>
                    </div>
                </div>
                <div className="flex-column">
                    <SubscribeComponent />
                </div>
            </main>
            <section className="mt-2">
                <span className="text-white inter font-14">© 2024 codeconsulter.com</span>
            </section>
        </div>
    </footer>)
}

export default Footer;